import { AbTest } from 'analytics/ab-service';
import { storyLanguagesLSService } from 'api/languages/storyLanguagesLSService';
import { Status } from 'components/organizms/StoryEditorForm/StatusService';
import { httpApi } from 'core/httpApi';
import { MonetizationOption } from 'types/monetization';

export interface IGetStoryByIdParams {
    storyId: string;
    offset?: number;
    author_id?: string;
    signal?: AbortSignal;
}

// todo разобраться с типами и полями Story, избавиться от snake_case
interface IGetStoryByIdTwistItem {
    title: string;
    hashId: string;
    publishedAt: string;
    purchased: boolean;
    hash: string;
    price: number;
    nickname: string;
    authorHashId: string;
    status: Status;
    monetization_option: MonetizationOption;
    purchasedForAmount: number | null; // todo unused
    ismature: boolean; // todo unused
    isuserauthor: boolean; // todo unused
    childTwistCount: number; // todo unused
}

interface IGetStoryByIdPathItem {
    hashId: string;
    title: string;
    body: string;
    publishedAt: string;
    twists: IGetStoryByIdTwistItem[] | null;
    purchasedTwists: IGetStoryByIdTwistItem[] | null;
    genre: string | null;
    tags: string[];
    authorHashId: string;
    nickname: string;
    status: Status;
    monetization_option: MonetizationOption;
    summary?: string | null;
}

export interface IGetStoryByIdResponse {
    path: IGetStoryByIdPathItem[];
}

export const getReadableStoryById = ({
    storyId,
    offset = 0,
    signal,
    author_id,
}: IGetStoryByIdParams) => {
    const offsetDirection = offset > 0 ? 'next' : offset < 0 ? 'previous' : '';
    const offsetPath = `/${offsetDirection}/${Math.abs(offset)}`;
    const fullPath = `stories/${storyId}${offsetDirection ? offsetPath : ''}`;

    /*
    Если нет оффсета, то язык не отправляем, потому что в этом случае у нас прямая ссылка на историю.
    C оффсетом нам нужен язык, чтобы next и previous истории были на нужном языке
    */
    const languageParam = offsetDirection
        ? {
              languages: storyLanguagesLSService.getQueryString(),
          }
        : {};

    const withTopParam = AbTest.isGroupB1152;

    return httpApi.v2.get<IGetStoryByIdResponse, IGetStoryByIdResponse>(fullPath, {
        signal,
        withCredentials: true,
        params: {
            author_id,
            ...languageParam,
            with_top: withTopParam,
        },
    });
};
