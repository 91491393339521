import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { MainBlock as ImageModalMainBlock } from '../../ImageModal/MainBlock/MainBlock';

interface IMainBlockProps {
    step: number;
}

const stepMessageId: Record<number, string> = {
    1: 'onboarding_modal.step_1.body',
    2: 'onboarding_modal.step_2.body',
    3: 'onboarding_modal.step_3.body',
};
export const MainBlock: FC<IMainBlockProps> = ({ step }) => (
    <ImageModalMainBlock>
        <FormattedMessage id={stepMessageId[step]} />
    </ImageModalMainBlock>
);
