import cn from 'classnames';
import { FC } from 'react';

import styles from './ImageBlock.module.css';

interface IImageBlockProps {
    step: number;
}

export const ImageBlock: FC<IImageBlockProps> = ({ step }) => (
    <div className={cn(styles['container'], styles[`step_${step}`])}>
        <div className={cn(styles['img'], styles[`img_bg_${step}`])} />
    </div>
);
