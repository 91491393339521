import { Status } from '../../components/organizms/StoryEditorForm/StatusService';
import { httpApi } from 'core/httpApi';

export interface IPatchStoryParams {
    storyId: string;
    title?: string;
    body?: string;
    isMature?: boolean;
    genre?: string | null;
    tags?: string[];
}

export interface IPatchStoryResponse {
    hashId: string;
    authorId: string;
    title: string;
    body: string;
    lang: string;
    isMature: boolean;
    createdAt: string;
    updatedAt: string;
    genre: string | null;
    tags: string[];
    status: Status;
}

export const patchStory = async ({
    storyId,
    title,
    body,
    isMature,
    genre,
    tags,
}: IPatchStoryParams) =>
    httpApi.v2.patch<IPatchStoryResponse, IPatchStoryResponse>(`stories/${storyId}`, {
        title,
        body,
        isMature,
        genre,
        tags,
    });
