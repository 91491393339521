import cn from 'classnames';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './LogoSidebar.module.css';
import { ReactComponent as OnionSvg } from 'assets/onion.svg';
import { Text } from 'components/atoms/Text';
import { FooterControls } from 'components/molecules/FooterControls';
import { WebinarLink } from 'components/molecules/WebinarLink/WebinarLink';
import { TitledLogo } from 'components/molecules/story/TitledLogo';
import { NewStoryButton } from 'components/organizms/myStories/NewStoryButton';
import { useIsTablet } from 'hooks/layout';
import { useThemeConfig } from 'hooks/theme/useThemeConfig';

export type LogoSidebarProps = {
    className?: string;
};

export const LogoSidebar: FC<LogoSidebarProps> = ({ className }) => {
    const isTablet = useIsTablet();
    const config = useThemeConfig((themeConfig) => themeConfig.logoSidebar);
    const showCreateButton = !isTablet;

    return (
        <aside className={cn(styles['aside'], className)}>
            <div className={styles['aside_content']}>
                <TitledLogo />
                {isTablet && <WebinarLink />}
                {!isTablet && (
                    <Text typo="minor" color="secondary" className={styles['subtitle']}>
                        <FormattedMessage id="left_sidebar.logo.description" />
                    </Text>
                )}
                {showCreateButton && <NewStoryButton className={styles['create_button']} />}
            </div>
            {!isTablet && (
                <>
                    <div className={cn(styles['aside_content'], styles['legal_links'])}>
                        <FooterControls />
                    </div>
                    {!config.onion.hidden && (
                        <div className={styles['onion']}>
                            <OnionSvg />
                        </div>
                    )}
                </>
            )}
        </aside>
    );
};
