import { FC } from 'react';

import { IModalTemplateImageProps } from '../../templates/ModalTemplateImage/ModalTemplateImage';
import { CloseButtonBlock } from './CloseButtonBlock/CloseButtonBlock';
import { ImageBlockPreloader } from './ImageBlockPreloader/ImageBlockPreloader';
import { ModalWindowBase } from 'components/organizms/ModalWindowBase';
import { ModalTemplateImage } from 'components/templates/ModalTemplateImage';

type IOnboardingModalProps = Omit<IModalTemplateImageProps, 'closeButton'> & {
    loading: boolean;
    onClose: () => void;
    preloaderClassName: string;
    groupAll?: boolean;
    allGroupClass?: string;
};

export const ImageModal: FC<IOnboardingModalProps> = ({
    loading,
    onClose,
    title,
    main,
    header,
    buttonBlock,
    image,
    preloaderClassName,
    group1ClassName,
    group2BodyClassName,
    className,
    groupAll,
    allGroupClass,
}) => (
    <ImageBlockPreloader loading={loading} className={preloaderClassName}>
        <ModalWindowBase onShadowClick={onClose}>
            <ModalTemplateImage
                allGroupClass={allGroupClass}
                groupAll={groupAll}
                header={header}
                image={image}
                title={title}
                main={main}
                className={className}
                closeButton={<CloseButtonBlock onClick={onClose} />}
                buttonBlock={buttonBlock}
                withSmallScreens={false}
                group1ClassName={group1ClassName}
                group2BodyClassName={group2BodyClassName}
            />
        </ModalWindowBase>
    </ImageBlockPreloader>
);
