import { FormattedMessage } from 'react-intl';

import { AnalyticsEvents } from 'analytics/events';
import { ExternalLink } from 'components/atoms/ExternalLink/ExternalLink';
import { Text } from 'components/atoms/Text';
import { externalLinks } from 'constants/externalLinks';

export const WebinarLink = () => {
    const handleWebinarClick = () => {
        AnalyticsEvents.sidebarSectionClick('free-webinar');
    };

    return (
        <ExternalLink href={externalLinks.freeWebinar} onClick={handleWebinarClick}>
            <Text typo="minor" color="secondary">
                <FormattedMessage id="left_sidebar.links.webinar" />
            </Text>
        </ExternalLink>
    );
};
