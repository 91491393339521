import { Status } from 'components/organizms/StoryEditorForm/StatusService';
import { MonetizationOption } from 'types/monetization';

export const twist11 = (purchased: boolean = false) => ({
    title: "A Glimpse into a Tech Titan's Next Chapter",
    body: "As Liu prepares for retirement, he reflects on his 30-year journey with TSMC. He's grateful for the privilege to have served as chairman after the company's renowned founder, Dr. Morris Chang, and now looks forward to dedicating his extensive semiconductor experience to other uses, spending more time with his family, and embarking on the next chapter of his life.",
    hashId: 'glz5jJD0Rr6WK9MX',
    publishedAt: '1970-01-01T00:00:00.006+00:00',
    nickname: 'Shanshan Kao',
    authorHashId: 'Shanshan Kao',
    purchased,
    purchasedForAmount: purchased ? 0 : null,
    ismature: false,
    isuserauthor: false,
    status: Status.PUBLISHED,
    price: 0,
    hash: 'TSMC0',
    monetization_option: MonetizationOption.Dynamic,
    childTwistCount: 0,
    twists: [],
    purchasedTwists: [],
    genre: null,
    tags: [],
});

export const twist12 = (purchased: boolean = false) => ({
    title: "The New Guard: TSMC's Impending Power Transition",
    body: "Following Liu's departure, TSMC's Vice Chairman and CEO C.C. Wei is set to fill the position, pending the board's approval. Wei, who joined TSMC in 1998 and has been on its board of directors since 2017, has an impressive academic background with a doctorate from Yale and a degree in electrical engineering from Taiwan's National Chiao Tung University.",
    hashId: 'AjQaxZm8LbmqPVrg',
    publishedAt: '1970-01-01T00:00:00.006+00:00',
    nickname: 'Shanshan Kao',
    authorHashId: 'Shanshan Kao',
    purchased,
    purchasedForAmount: purchased ? 10 : null,
    ismature: false,
    isuserauthor: false,
    status: Status.PUBLISHED,
    price: 10,
    hash: 'TSMC17',
    monetization_option: MonetizationOption.Dynamic,
    childTwistCount: 0,
    twists: [],
    purchasedTwists: [],
    genre: null,
    tags: [],
});

export const twist13 = (purchased: boolean = false) => ({
    title: "TSMC's Future Strategy: Global Expansion or Misstep?",
    body: "Wei's takeover comes at a time when TSMC is planning expansions in Germany, Japan, and the U.S. state of Arizona to meet the increasing global demand for artificial intelligence chips. Amid geopolitical tensions across the Taiwan Strait, this expansion is seen as a strategic move to de-risk its supply chain. Despite this, Taiwan will continue to be the major location for its manufacturing and most advanced chips. However, Wei expresses concerns about the future of globalization and free trade in the chips sector, and believes that moving the entire production chain to the U.S. could escalate costs and impede the chip market's growth.",
    hashId: '8yVLob6dL06WqZPv',
    publishedAt: '1970-01-01T00:00:00.006+00:00',
    nickname: 'Shanshan Kao',
    authorHashId: 'Shanshan Kao',
    purchased,
    purchasedForAmount: purchased ? 15 : null,
    ismature: false,
    isuserauthor: false,
    status: Status.PUBLISHED,
    price: 15,
    hash: 'TSMC11',
    monetization_option: MonetizationOption.Dynamic,
    childTwistCount: 0,
    twists: [],
    purchasedTwists: [],
    genre: null,
    tags: [],
});

export const story1 = (purchasedTwistsHashes: string[] = []) => {
    const twists = [twist11()].filter((twist) => !twist);
    const purchasedTwists = [twist11()].filter((twist) => !twist);
    [twist11(), twist12(), twist13()].forEach((twist, index) => {
        if (purchasedTwistsHashes.includes(twist.hashId)) {
            if (index === 0) {
                purchasedTwists.push(twist11(true));
            } else if (index === 1) {
                purchasedTwists.push(twist12(true));
            } else if (index === 2) {
                purchasedTwists.push(twist13(true));
            }
        } else {
            twists.push(twist);
        }
    });
    return {
        title: 'TSMC Leadership Reshuffle: Chairman Steps Down As CEO Takes Over',
        body: 'Taiwan Semiconductor Manufacturing Co. (TSMC) announced that its Chairman Mark Liu will retire in 2024. Liu had joined TSMC in 1993 and served as its chairman since 2018, leading the company through a worldwide chip shortage that placed TSMC into a geopolitical conflict between China and the U.S.',
        hashId: '0Ov7qoDq8WwPX4BM',
        publishedAt: '1970-01-01T00:00:00.006Z',
        twists,
        tags: ['Contemporary', 'Friendship', 'Romance', 'Slice-Of-Life', 'Young Adult'],
        genre: null,
        nickname: 'Shanshan Kao',
        authorHashId: 'Shanshan Kao',
        status: Status.PUBLISHED,
        monetization_option: MonetizationOption.Dynamic,
        purchasedTwists: purchasedTwists.length ? purchasedTwists : [],
        summary:
            'smc_chairman_mark_liu_retiring_in_2024_after_leading_the_company_through_a_chip_shortage_and_geopolitical_conflict',
        channel: null,
    };
};

export const twist21 = (purchased: boolean = false) => ({
    title: 'Diversified Revenue Streams',
    body: 'Microtransactions broaden revenue beyond subscriptions, attracting a wider user base, and generating additional income.',
    hashId: '3joGrbm1Knwx9yev',
    publishedAt: '1970-01-01T00:00:00.006+00:00',
    nickname: 'Story3',
    authorHashId: 'Story3',
    purchased,
    purchasedForAmount: purchased ? 0 : null,
    ismature: false,
    isuserauthor: false,
    status: Status.PUBLISHED,
    price: 0,
    hash: 'STORY17',
    monetization_option: MonetizationOption.Dynamic,
    childTwistCount: 0,
    twists: [],
    purchasedTwists: [],
    genre: null,
    tags: [],
});

export const twist22 = (purchased: boolean = false) => ({
    title: 'Optimized Audience Engagement and Retention',
    body: 'Story3 fosters direct engagement by delivering tailored content, forging deeper connections, and nurturing user loyalty. This flexible platform comprehends audience preferences, refining content curation for increased satisfaction, and flexible payments honor content preferences without fixed subscriptions, further enhancing user retention.',
    hashId: 'zv15VbDkJ2wd8NMg',
    publishedAt: '1970-01-01T00:00:00.006+00:00',
    nickname: 'Story3',
    authorHashId: 'Story3',
    purchased,
    purchasedForAmount: purchased ? 10 : null,
    ismature: false,
    isuserauthor: false,
    status: Status.PUBLISHED,
    price: 10,
    hash: 'STORY11',
    monetization_option: MonetizationOption.Dynamic,
    childTwistCount: 0,
    twists: [],
    purchasedTwists: [],
    genre: null,
    tags: [],
});

export const twist23 = (purchased: boolean = false) => ({
    title: 'Enhanced Creative Freedom and Content Curation',
    body: "Story3 champions content experimentation, pushing the boundaries of traditional storytelling. Authors cultivate a loyal readership, enhancing their personal brand within Story3's engaging platform. This creative freedom intertwines with optimized content curation, resulting in a dynamic, satisfying user experience.",
    hashId: 'l19V3KD23PmxJyQk',
    publishedAt: '1970-01-01T00:00:00.006+00:00',
    nickname: 'Story3',
    authorHashId: 'Story3',
    purchased,
    purchasedForAmount: purchased ? 15 : null,
    ismature: false,
    isuserauthor: false,
    status: Status.PUBLISHED,
    price: 15,
    hash: 'STORY0',
    monetization_option: MonetizationOption.Dynamic,
    childTwistCount: 0,
    twists: [],
    purchasedTwists: [],
    genre: null,
    tags: [],
});

export const story2 = (purchasedTwistsHashes: string[] = []) => {
    const twists = [twist21()].filter((twist) => !twist);
    const purchasedTwists = [twist21()].filter((twist) => !twist);
    [twist21(), twist22(), twist23()].forEach((twist, index) => {
        if (purchasedTwistsHashes.includes(twist.hashId)) {
            if (index === 0) {
                purchasedTwists.push(twist21(true));
            } else if (index === 1) {
                purchasedTwists.push(twist22(true));
            } else if (index === 2) {
                purchasedTwists.push(twist23(true));
            }
        } else {
            twists.push(twist);
        }
    });
    return {
        title: 'Story3: Engaging Content, Empowered Revenue, Limitless Creativity',
        body: 'Media companies excel in crafting narratives and delivering information. Through Story3, content monetization takes a revolutionary turn with microtransactions, empowering users to personalize their reading experiences by paying solely for content that resonates with them, leading to an unforgettable journey.',
        hashId: 'Y1vKpawLGd6yOlZj',
        publishedAt: '1970-01-01T00:00:00.006Z',
        twists,
        tags: ['Monetization', 'Microtransactions', 'Engagement', 'Creativity', 'Curation'],
        genre: null,
        nickname: 'Story3',
        authorHashId: 'Story3',
        status: Status.PUBLISHED,
        monetization_option: MonetizationOption.Dynamic,
        purchasedTwists: purchasedTwists.length ? purchasedTwists : [],
        summary:
            'media_companies_crafting_narratives_and_delivering_information_through_story3_revolutionizing_monetization_with_microtransactions',
        channel: null,
    };
};
