import { FC, lazy, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';

import { Spinner } from '../../atoms/Spinner';
import { ModalHeaderBasic } from '../../molecules/ModalHeaderBasic';
import { IReplenishBalanceModalWindowProps } from './ReplenishBalanceModalWindow';
import { ModalWindowBase } from 'components/organizms/ModalWindowBase';
import { ModalTemplateBasic } from 'components/templates/ModalTemplateBasic';

const ReplenishBalanceModalWindow = lazy(
    () => import('components/organizms/ReplenishBalanceModalWindow/ReplenishBalanceModalWindow')
);

const ReplenishBalanceModalWindowLazy: FC<IReplenishBalanceModalWindowProps> = ({
    onClose,
    balance,
}) => (
    <ModalWindowBase onShadowClick={onClose}>
        <ModalTemplateBasic
            header={
                <ModalHeaderBasic onClose={onClose}>
                    <FormattedMessage id="profile.balance.replenish" />
                </ModalHeaderBasic>
            }
        >
            <Suspense fallback={<Spinner size={20} />}>
                <ReplenishBalanceModalWindow onClose={onClose} balance={balance} />
            </Suspense>
        </ModalTemplateBasic>
    </ModalWindowBase>
);

export default ReplenishBalanceModalWindowLazy;
