import * as Sentry from '@sentry/react';
import { lazy } from 'react';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';

import { App } from '../../../App';
import { AuthRequired } from './AuthRequired';
import { ERoutes } from './routes';
import { AgentInvite } from 'components/molecules/AgentInvite/AgentInvite';
import { EMyStoriesTabs } from 'components/organizms/myStories/MyStoriesTabs/types';
import { CaughtErrorPage } from 'components/pages/CaughtErrorPage';
import { EFAQTabs } from 'components/pages/FAQPage/types';
import { ForbesEntryPoint } from 'components/pages/forbes/ForbesEntryPoint';
import { WithRedirectToWritersFAQ } from 'components/templates/WithRedirectToWritersFAQ/WithRedirectToWritersFAQ';
import { WithSidebar } from 'components/templates/WithSidebar';
import { PARTNERS_URL } from 'contexts/PartnerRouter/PartnerRouterContext';
import { routesOptions as eightyLevelDevRoutesOptions } from 'themes/partners/80lv-dev/routes';
import { routesOptions as eightyLevelRoutesOptions } from 'themes/partners/80lv/routes';
import { forbesRoutesOptions } from 'themes/partners/forbes/routes';
import { routesOptions as newYorkTimesRoutesOptions } from 'themes/partners/nytimes/routes';
import { IDisableRoutesOptions } from 'themes/types/routes';

const MainPageLazy = lazy(() => import('pages/mainPage'));
const ProfilePageLazy = lazy(() => import('components/pages/ProfilePage/ProfilePage'));
const LanguageSettingsPageLazy = lazy(() => import('components/pages/LanguageSettingsPage'));
const StoryPageLazy = lazy(() => import('components/pages/StoryPage/StoryPage'));
const FromReferralLinkToStory = lazy(
    () => import('components/pages/StoryPage/FromReferralLinkToStory')
);
const MyStoriesPageLazy = lazy(() => import('components/pages/MyStoriesPage'));
const EditStoryPage = lazy(() => import('components/pages/EditStoryPage'));
const TransactionsPage = lazy(() => import('components/pages/TransactionsPage'));
const FAQPage = lazy(() => import('components/pages/FAQPage/FAQPage'));
const LegalPage = lazy(() => import('components/pages/LegalPage/LegalPage'));
const PrivacyPolicyPage = lazy(
    () => import('components/pages/PrivacyPolicyPage/PrivacyPolicyPage')
);
const RefundPolicyPage = lazy(() => import('components/pages/RefundPolicyPage/RefundPolicyPage'));
const ContentPolicyPage = lazy(
    () => import('components/pages/ContentPolicyPage/ContentPolicyPage')
);
const CookieStatementPage = lazy(
    () => import('components/pages/CookieStatementPage/CookieStatementPage')
);
const DmcaPolicyPage = lazy(() => import('components/pages/DmcaPolicyPage/DmcaPolicyPage'));

const TermsOfServicePage = lazy(
    () => import('components/pages/TermsOfServicePage/TermsOfServicePage')
);

const getCoreChildren = (prefix: PARTNERS_URL, options?: IDisableRoutesOptions) => {
    const getMyStoriesRedirectUrl = () => {
        if (!options?.disableMyStoriesWriting) {
            return prefix + ERoutes.MyStoriesWriting;
        }
        if (!options?.disableMyStoriesReading) {
            return prefix + ERoutes.MyStoriesReading;
        }
        return prefix + ERoutes.Main;
    };

    return [
        !options?.disableTransactions
            ? {
                  path: prefix + ERoutes.Transactions,
                  element: (
                      <AuthRequired>
                          <TransactionsPage />
                      </AuthRequired>
                  ),
              }
            : null,
        !options?.disableProfile
            ? {
                  path: prefix + ERoutes.Profile,
                  element: (
                      <AuthRequired>
                          <ProfilePageLazy />
                      </AuthRequired>
                  ),
              }
            : null,
        !options?.disableLanguageSettings
            ? {
                  path: prefix + ERoutes.LanguageSettings,
                  element: <LanguageSettingsPageLazy />,
              }
            : null,
        !options?.disableLanguageSettings
            ? {
                  path: prefix + ERoutes.LanguageSettingsDev,
                  element: <LanguageSettingsPageLazy />,
              }
            : null,
        !options?.disableMain
            ? {
                  path: prefix + ERoutes.Main,
                  element: <MainPageLazy />,
              }
            : null,
        !options?.disableStory
            ? {
                  path: prefix + ERoutes.Story,
                  element: <StoryPageLazy />,
              }
            : null,
        !options?.disableAgentFAQ
            ? {
                  path: prefix + ERoutes.AgentFAQ,
                  element: <FAQPage tab={EFAQTabs.Agent} />,
              }
            : null,
        !options?.disableWritersFAQ
            ? {
                  path: prefix + ERoutes.WritersFAQ,
                  element: <FAQPage tab={EFAQTabs.Writer} />,
              }
            : null,
        !options?.disableWritersFAQ && !options?.disableAgentFAQ
            ? {
                  path: prefix + ERoutes.FAQ,
                  element: <Navigate to={prefix + ERoutes.WritersFAQ} replace />,
              }
            : null,
        !options?.disableLegalDocuments
            ? {
                  path: prefix + ERoutes.LegalDocuments,
                  element: <LegalPage />,
              }
            : null,
        {
            path: prefix + ERoutes.ContentPolicy,
            element: <ContentPolicyPage />,
        },
        {
            path: prefix + ERoutes.DMCAPolicy,
            element: <DmcaPolicyPage />,
        },
        {
            path: prefix + ERoutes.PrivacyPolicy,
            element: <PrivacyPolicyPage />,
        },
        {
            path: prefix + ERoutes.RefundPolicy,
            element: <RefundPolicyPage />,
        },
        {
            path: prefix + ERoutes.TermsOfService,
            element: <TermsOfServicePage />,
        },
        {
            path: prefix + ERoutes.CookieStatement,
            element: <CookieStatementPage />,
        },
        {
            path: prefix + ERoutes.CreateStory,
            element: <EditStoryPage />,
        },
        !options?.disableMyStories
            ? {
                  path: prefix + ERoutes.MyStories,
                  element: <Navigate to={getMyStoriesRedirectUrl()} replace />,
              }
            : null,
        !options?.disableMyStoriesReading
            ? {
                  path: prefix + ERoutes.MyStoriesReading,
                  element: (
                      <AuthRequired>
                          <MyStoriesPageLazy tabName={EMyStoriesTabs.Reading} />
                      </AuthRequired>
                  ),
              }
            : null,
        !options?.disableMyStoriesWriting
            ? {
                  path: prefix + ERoutes.MyStoriesWriting,
                  element: (
                      <AuthRequired>
                          <MyStoriesPageLazy tabName={EMyStoriesTabs.Writing} />
                      </AuthRequired>
                  ),
              }
            : null,
        !options?.disableCreateStory
            ? {
                  path: prefix + ERoutes.CreateStory,
                  element: (
                      <AuthRequired>
                          <WithRedirectToWritersFAQ>
                              <EditStoryPage />
                          </WithRedirectToWritersFAQ>
                      </AuthRequired>
                  ),
              }
            : null,
        !options?.disableEditStory
            ? {
                  path: prefix + ERoutes.EditStory,
                  element: (
                      <AuthRequired>
                          <WithRedirectToWritersFAQ>
                              <EditStoryPage />
                          </WithRedirectToWritersFAQ>
                      </AuthRequired>
                  ),
              }
            : null,
        !options?.disableReferralStory
            ? {
                  path: prefix + ERoutes.ReferralStory,
                  element: <FromReferralLinkToStory />,
              }
            : null,
        {
            path: prefix + ERoutes.AgentInvite,
            element: <AgentInvite />,
        },
    ].filter((route) => !!route);
};

export const createRouter = () => {
    const routerConfig: RouteObject[] = [
        {
            element: <App />,
            errorElement: <CaughtErrorPage />,
            children: [
                {
                    path: PARTNERS_URL.FORBES + ERoutes.EntryPoint,
                    element: <ForbesEntryPoint />,
                },
                {
                    path: PARTNERS_URL.FORBES,
                    element: <WithSidebar />,
                    // @ts-expect-error: we filter null above
                    children: getCoreChildren(PARTNERS_URL.FORBES, forbesRoutesOptions),
                },
                {
                    path: PARTNERS_URL.NEW_YORK_TIMES,
                    element: <WithSidebar />,
                    // @ts-expect-error: we filter null above
                    children: getCoreChildren(
                        PARTNERS_URL.NEW_YORK_TIMES,
                        newYorkTimesRoutesOptions
                    ),
                },
                {
                    path: PARTNERS_URL.EIGHTY_LEVEL,
                    element: <WithSidebar />,
                    // @ts-expect-error: we filter null above
                    children: getCoreChildren(PARTNERS_URL.EIGHTY_LEVEL, eightyLevelRoutesOptions),
                },
                {
                    path: PARTNERS_URL.EIGHTY_LEVEL_DEV,
                    element: <WithSidebar />,
                    // @ts-expect-error: we filter null above
                    children: getCoreChildren(
                        PARTNERS_URL.EIGHTY_LEVEL_DEV,
                        eightyLevelDevRoutesOptions
                    ),
                },
                {
                    path: ERoutes.Main,
                    element: <WithSidebar />,
                    // @ts-expect-error: we filter null above
                    children: getCoreChildren(PARTNERS_URL.DEFAULT),
                },
            ],
        },
    ];

    const sentryBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

    return sentryBrowserRouter(routerConfig);
};
