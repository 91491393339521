import { Status } from '../../components/organizms/StoryEditorForm/StatusService';
import { httpApi } from 'core/httpApi';

export interface IGetStoryParams {
    storyId: string;
}

export interface IGetStoryResponse {
    hashId: string;
    authorId: string;
    title: string;
    body: string;
    lang: string;
    isMature: boolean;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    genre: string;
    tags: string[];
    status?: Status;
}

export const getStory = async ({ storyId }: IGetStoryParams) =>
    httpApi.v2.get<IGetStoryResponse, IGetStoryResponse>(`twists/${storyId}`);
