import cn from 'classnames';
import { FC } from 'react';
import { FormattedNumber } from 'react-intl';

import styles from './StepBlock.module.css';
import { Text } from 'components/atoms/Text';

interface IStepBlockProps {
    step: number;
    finalStep: number;
}

export const StepBlock: FC<IStepBlockProps> = ({ step, finalStep }) => (
    <div className={cn(styles['container'], styles[`step_${step}`])}>
        <Text typo="label">
            <FormattedNumber value={step} minimumIntegerDigits={2} />
        </Text>
        <Text color="tertiary">—</Text>
        <Text color="tertiary">
            <FormattedNumber value={finalStep} minimumIntegerDigits={2} />
        </Text>
    </div>
);
