import { localStorageService } from 'utils/localStorageService';

export enum OnboardingStep {
    One = 1,
    Two = 2,
    Three = 3,
    Finished = 4,
}

const onboardingLSService = localStorageService<unknown>('story3_onboarding_step');

export const onboardingStepStorage = {
    set: (value: number) => onboardingLSService.set(value),
    get: () => {
        const savedValue = onboardingLSService.get();
        return typeof savedValue === 'number' && Object.values(OnboardingStep).includes(savedValue)
            ? savedValue
            : OnboardingStep.One;
    },
};
