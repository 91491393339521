import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { TitleBlock as ImageModalTitleBlock } from '../../ImageModal/TitleBlock/TitleBlock';
import styles from './TitleBlock.module.css';

interface ITitleBlockProps {
    step: number;
}

const stepMessageId: Record<number, string> = {
    1: 'onboarding_modal.step_1.title',
    2: 'onboarding_modal.step_2.title',
    3: 'onboarding_modal.step_3.title',
};

export const TitleBlock: FC<ITitleBlockProps> = ({ step }) => (
    <ImageModalTitleBlock className={styles[`step_${step}`]}>
        <FormattedMessage id={stepMessageId[step]} />
    </ImageModalTitleBlock>
);
