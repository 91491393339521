import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
    reducer: () => {},
});

/*if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../slices/index.ts', () => store.replaceReducer(rootReducer));
}*/

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
