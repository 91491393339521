import { FC } from 'react';

import { ImageModal } from '../ImageModal/ImageModal';
import { ImageBlock } from './ImageBlock/ImageBlock';
import styles from './ImageBlock/ImageBlock.module.css';
import { MainBlock } from './MainBlock/MainBlock';
import { NextButtonBlock } from './NextButtonBlock/NextButtonBlock';
import { StepBlock } from './StepBlock/StepBlock';
import { TitleBlock } from './TitleBlock/TitleBlock';

interface IOnboardingModalProps {
    loading: boolean;
    step: number;
    finalStep: number;
    onNext: () => void;
    onClose: () => void;
}

export const OnboardingModal: FC<IOnboardingModalProps> = ({
    loading,
    step,
    finalStep,
    onNext,
    onClose,
}) => (
    <ImageModal
        loading={loading}
        onClose={onClose}
        header={<StepBlock step={step} finalStep={finalStep} />}
        title={<TitleBlock step={step} />}
        main={<MainBlock step={step} />}
        image={<ImageBlock step={step} />}
        preloaderClassName={styles[`step_${step}`]}
        buttonBlock={<NextButtonBlock hasNext={step < finalStep} onClick={onNext} />}
    />
);
