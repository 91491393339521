import { FC, PropsWithChildren, Suspense, useCallback, useMemo, useState } from 'react';

import ReplenishBalanceModalWindowLazy from '../../components/organizms/ReplenishBalanceModalWindow/ReplenishBalanceModalWindowLazy';
import { ReplenishBalanceModalContext } from './ReplenishBalanceModalContext';
import { useUserBalanceQuery } from 'hooks/queries/balance';

export const ReplenishBalanceModalContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isDisplayed, setIsDisplayed] = useState<boolean>(false);
    const { data: balance } = useUserBalanceQuery();

    const hide = useCallback(() => setIsDisplayed(false), []);
    const display = useCallback(() => setIsDisplayed(true), []);

    const value = useMemo(
        () => ({
            isDisplayed,
            hide,
            display,
        }),
        [isDisplayed, hide, display]
    );

    return (
        <ReplenishBalanceModalContext.Provider value={value}>
            {children}
            {isDisplayed && (
                <Suspense>
                    <ReplenishBalanceModalWindowLazy onClose={hide} balance={balance} />
                </Suspense>
            )}
        </ReplenishBalanceModalContext.Provider>
    );
};
