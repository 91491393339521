import { Status } from 'components/organizms/StoryEditorForm/StatusService';
import { httpApi } from 'core/httpApi';
import { IMyStory } from 'types/myStories';

interface IGetMyStorySchema {
    hashId: string;
    title: string;
    body: string;
    tags: string[];
    updated_at: string;
    status: Status;
    isDeletable: boolean;
    views: number;
    summary: string;
}

type GetMyStoriesResponseSchema = IGetMyStorySchema[];

const mapResponseToMyStories = (response: GetMyStoriesResponseSchema): IMyStory[] =>
    response.map(
        (responseItem: IGetMyStorySchema): IMyStory => ({
            id: responseItem.hashId,
            title: responseItem.title,
            body: responseItem.body,
            status: responseItem.status,
            isDeletable: responseItem.isDeletable,
            lastEditDate: new Date(responseItem.updated_at),
            updatedAt: responseItem.updated_at,
            tags: responseItem.tags || [],
            views: responseItem.views,
            summary: responseItem.summary,
        })
    );

export const getMyStories = async (): Promise<IMyStory[]> => {
    const response: GetMyStoriesResponseSchema = await httpApi.v2.get('stories/my');
    return mapResponseToMyStories(response);
};
