import { FC, lazy, Suspense } from 'react';

import { Spinner } from '../../atoms/Spinner';
import { IPublishStoryModalProps } from './AskEmailModal';
import { ModalWindowBase } from 'components/organizms/ModalWindowBase';
import { ModalTemplateBasic } from 'components/templates/ModalTemplateBasic';

const AskEmailModal = lazy(() => import('components/molecules/askEmailModal/AskEmailModal'));

const AskEmailModalLazy: FC<IPublishStoryModalProps> = ({ onFinishRefetch }) => (
    <ModalWindowBase>
        <ModalTemplateBasic>
            <Suspense fallback={<Spinner size={20} />}>
                <AskEmailModal onFinishRefetch={onFinishRefetch} />
            </Suspense>
        </ModalTemplateBasic>
    </ModalWindowBase>
);

export default AskEmailModalLazy;
