import { FC } from 'react';

import { Button } from '../../../atoms/Button/Button';
import styles from './FloatingLayout.module.css';
import { INewStoryButtonLayoutProps } from './types';
import { ReactComponent as FeatherIcon } from 'assets/feather.svg';

export const FloatingLayout: FC<INewStoryButtonLayoutProps> = ({
    className,
    isDisabled,
    onClick,
}) =>
    isDisabled ? null : (
        <Button
            className={className}
            onClick={onClick}
            icon={<FeatherIcon className={styles['icon']} height={24} />}
            size="lg"
            variant="primary"
        />
    );
