import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../../atoms/Button/Button';
import { INewStoryButtonLayoutProps } from './types';
import { ReactComponent as FeatherIcon } from 'assets/feather.svg';

export const StaticLayout: FC<INewStoryButtonLayoutProps> = ({
    className,
    isDisabled,
    onClick,
}) => (
    <Button
        className={className}
        onClick={onClick}
        size="lg"
        variant="primary"
        isDisabled={isDisabled}
        icon={<FeatherIcon height={24} />}
        iconPosition="before"
        stretched
    >
        <FormattedMessage id="my_stories.create_new_story" />
    </Button>
);
