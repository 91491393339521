import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as ArrowRight } from '../../../../assets/arrow-right.svg';
import { Button } from '../../../atoms/Button/Button';
import { ButtonBlock } from '../../ImageModal/ButtonBlock/ButtonBlock';
import styles from './NextButtonBlock.module.css';

interface INextButtonBlockProps {
    hasNext: boolean;
    onClick: () => void;
}

export const NextButtonBlock: FC<INextButtonBlockProps> = ({ hasNext, onClick }) => (
    <div className={styles['clickable']} onClick={onClick}>
        <ButtonBlock>
            <Button className={styles['button']} stretched>
                {!hasNext && <FormattedMessage id="onboarding_modal.button_finish" />}
                {hasNext && (
                    <span className={styles['text']}>
                        <span />
                        <FormattedMessage id="onboarding_modal.button_next" />
                        {hasNext && <ArrowRight width={24} height={24} />}
                    </span>
                )}
            </Button>
        </ButtonBlock>
    </div>
);
