import { Status } from 'components/organizms/StoryEditorForm/StatusService';
import { httpApi } from 'core/httpApi';
import { IOpenedTwist } from 'types/myStories';

interface IGetOpenedTwistsSchemaItem {
    storyHashId: string;
    storyTitle: string;
    storyAuthorNickname: string;
    twistHashId: string;
    twistTitle: string;
    twistAuthorNickname: string;
    twistStatus: Status;
    lastEditDate: string;
    readAt: string;
}

type OpenedTwistsResponseSchema = IGetOpenedTwistsSchemaItem[];

const mapResponseToMyTwists = (response: OpenedTwistsResponseSchema): IOpenedTwist[] =>
    response.map((responseItem: IGetOpenedTwistsSchemaItem) => ({
        storyHashId: responseItem.storyHashId,
        storyTitle: responseItem.storyTitle,
        storyAuthorNickname: responseItem.storyAuthorNickname,
        twistHashId: responseItem.twistHashId,
        twistTitle: responseItem.twistTitle,
        twistAuthorNickname: responseItem.twistAuthorNickname,
        twistStatus: responseItem.twistStatus,
        readAtDate: new Date(responseItem.lastEditDate),
    }));

export const getOpenedTwists = async (): Promise<IOpenedTwist[]> => {
    const response: OpenedTwistsResponseSchema = await httpApi.v2.get('twists/my-opened-twists');
    return mapResponseToMyTwists(response);
};
