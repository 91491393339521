import { useEffect, useState } from 'react';

import { OnboardingModal } from './OnboardingModal';
import { OnboardingStep, onboardingStepStorage } from './onboardingSteps';
import { AnalyticsEvents } from 'analytics/events';

interface IUseOnboardingModalArgs {
    initEnabled: boolean;
}

export const useOnboardingModal = ({ initEnabled }: IUseOnboardingModalArgs) => {
    const [step, setStep] = useState(onboardingStepStorage.get());

    const updateStep = (value: OnboardingStep) => {
        setStep(value);
        onboardingStepStorage.set(value);
    };

    const handleClose = () => {
        updateStep(OnboardingStep.Finished);
        AnalyticsEvents.onboardingModalClose(step);
    };

    const handleNext = () => {
        updateStep(step + 1);
        AnalyticsEvents.onboardingModalNext(step);
    };

    const modalVisible = initEnabled && step !== OnboardingStep.Finished;

    useEffect(() => {
        if (modalVisible) {
            AnalyticsEvents.onboardingModalShow(step);
        }
    }, [step]);

    return modalVisible ? (
        <OnboardingModal
            loading={false}
            step={step}
            finalStep={OnboardingStep.Three}
            onNext={handleNext}
            onClose={handleClose}
        />
    ) : null;
};
